import { configureStore} from '@reduxjs/toolkit';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import { authSlice, backgroundSlice, searchSlice } from './slice';
import storage from 'redux-persist/lib/storage';

export const { setAuthenticated, setAccessToken, setUser, logout } = authSlice.actions;
export const { setLastSearchItem } = searchSlice.actions;
export const { setMessage, closeBackgroundMessage } = backgroundSlice.actions;

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
};

const persistedAuthReducer = persistReducer(persistConfig, authSlice.reducer);
const persistedSearchReducer = persistReducer(persistConfig, searchSlice.reducer);
const persistedBackgroundReducer = persistReducer(persistConfig, backgroundSlice.reducer);

const store = configureStore({
  reducer: {
    auth: persistedAuthReducer,
    search: persistedSearchReducer,
    background: persistedBackgroundReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;

export default store;
