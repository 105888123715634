import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import type { TStylesConstant, TStylesVariant } from '@/lib/types'

export function getStyleVariant<T extends TStylesConstant>(
  css: T,
  userChoice: TStylesVariant<T>
) {
  const variantKey = (userChoice.variant ?? css.defaults?.variant) as keyof (
    | T['variants']
    | undefined
  )
  const sizeKey = (userChoice.size ?? css.defaults?.size) as keyof (
    | T['sizes']
    | undefined
  )
  return cn((css.variants as any)?.[variantKey], (css.sizes as any)?.[sizeKey])
}

export function findTruthy<T>(...inputs: T[]): T | undefined {
  return inputs.find(Boolean)
}

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}
