import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface AuthState {
  isAuthenticated: boolean;
  accessToken: string | null;
  user: string | null;
}

const authInitialState: AuthState = {
  isAuthenticated: false,
  accessToken: null,
  user: null
};

interface SearchState {
  lastSearchItem: any;
}

const searchInitialState: SearchState = {
  lastSearchItem: {},
};

interface BackgroundState {
  show: boolean;
  closable?: boolean;
  message?: string;
}

const backgroundInitialState: BackgroundState = {
  show: false,
  closable: true,
};


export const authSlice = createSlice({
  name: 'auth',
  initialState: authInitialState,
  reducers: {
    setAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
    },
    setAccessToken: (state, action: PayloadAction<string | null>) => {
      state.accessToken = action.payload;
    },
    setUser: (state, action: PayloadAction<string | null>) => {
      state.user = action.payload;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.accessToken = null;
      state.user = null;
    }
  },
});

export const searchSlice = createSlice({
  name: 'search',
  initialState: searchInitialState,
  reducers: {
    setLastSearchItem: (state, action: PayloadAction<any>) => {
      state.lastSearchItem = action.payload;
    },
  },
});

export const backgroundSlice = createSlice({
  name: 'background',
  initialState: backgroundInitialState,
  reducers: {
    setMessage: (state, action: PayloadAction<{ show: boolean; message: string; closable?: boolean; }>) => {
      state.show = action.payload.show;
      state.message = action.payload.message;
      if (action.payload.closable) {
        state.closable = action.payload.closable;
      }
    },
    closeBackgroundMessage: (state) => {
      state.show = false;
      state.closable = false;
      state.message = "";
    }
  },
});